<template>
  <div class="">
    <el-card class="box-card">
      <div class="buyprop">
        <el-button type="primary" icon="el-icon-circle-plus" @click="tobuy()"
          >购买道具</el-button
        >
      </div>
      <div class="" style="min-height: 500px">
        <el-table
          :data="tableData"
          v-loading="table_loading"
          :show-header="false"
          element-table_loading-text="加载中"
          class="boderline"
        >
          <el-table-column type="index"></el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <span v-if="scope.row.type == 1">购买聊天卡</span>
              <span v-if="scope.row.type == 2">购买短信提醒卡</span>
              <span v-if="scope.row.type == 3">购买ios聊天卡</span>
              <span v-if="scope.row.type == 4">购买ios短信提醒卡</span>
              <span v-if="scope.row.type == 5">购买套餐</span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <div class="" v-if="scope.row.type == 5">
                <span class="zhang"
                  >{{ scope.row.count }}张聊天卡，{{
                    scope.row.count2
                  }}张短信卡</span
                >，共{{ scope.row.money }}元
              </div>
              <div class="" v-if="scope.row.type == 1 || scope.row.type == 3">
                <span class="zhang">{{ scope.row.count }}张聊天卡</span>，共{{
                  scope.row.money
                }}元
              </div>
              <div class="" v-if="scope.row.type == 2 || scope.row.type == 4">
                <span class="zhang">{{ scope.row.count }}张短信卡</span>，共{{
                  scope.row.money
                }}元
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="displayName"></el-table-column>
          <el-table-column prop="createDate"></el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      table_loading: false,
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 20,
        count: 1,
        dtos: [],
      },
    };
  },
  activated() {
    this.fetchData(this.pageInfo.pageNum);
    this.getList();
  },
  methods: {
    getList() {
      this.$api.propRecord("get").then((res) => {
        // console.log(res);
        this.tableData = res.data;
      });
    },
    fetchData(page = 1) {
      let vm = this;
      // vm.table_loading = true
      vm.pageInfo.pageNum = page;
      // vm.formInline.pageNum = page

      vm.table_loading = false;
    },
    tobuy() {
      this.$router.push("/propsmall");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-card {
  border-radius: 0px;
  border: none;
  box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}
/deep/.el-card__body {
  padding: 0 30px;
}
.buyprop {
  margin: 30px 0;
  /deep/ .el-button {
    border-radius: 0px;
    width: 135px;
    background: #00bcff;
    border: none;
  }
}
.boderline {
  width: 100%;
  border-right: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
  border-top: 1px solid #f3f3f3;
}
/deep/.el-table td,
/deep/.el-table th {
  text-align: center;
  color: #111111;
  font-size: 14px;
}
.zhang {
  color: #666666;
}
</style>
